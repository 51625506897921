import * as Repository from "./task.repository";
import {
  GroupDTO,
  GroupTaskDTO,
  TaskAssignDTO,
  TaskPaymentDTO,
  TaskCustomerDTO,
  CustomerTaskDTO,
  TaskCycleStatusDTO,
  CreateTaskPaymentDTO,
  TaskEmployeeStatusDTO
} from "./task.dto";

async function getCycleStatus(): Promise<TaskCycleStatusDTO> {
  const taskCycleStatus: TaskCycleStatusDTO = await Repository.getCycleStatus();

  return taskCycleStatus;
}

async function searchTask(
  deviceSerialNumber?: string,
  bpNumber?: string
): Promise<TaskCustomerDTO> {
  const task: TaskCustomerDTO = await Repository.searchTask({
    bpNumber,
    deviceSerialNumber
  });

  return task;
}

async function getGroups(): Promise<GroupDTO[]> {
  const groups: GroupDTO[] = await Repository.getGroups();

  return groups;
}

async function getTasksOfAGroup(groupDetails: {
  groupName: string;
  area: string;
  postalCode: string;
}): Promise<GroupTaskDTO> {
  const tasksOfAGroup: GroupTaskDTO = await Repository.getTasksOfAGroup(
    groupDetails
  );

  return tasksOfAGroup;
}

async function getEmployeeStatus(
  employeeId: string
): Promise<TaskEmployeeStatusDTO> {
  const employeeStatus: TaskEmployeeStatusDTO = await Repository.getEmployeeStatus(
    employeeId
  );

  return employeeStatus;
}

async function getTasksOfACustomer(
  customerId: string
): Promise<CustomerTaskDTO> {
  const tasksOfACustomer: CustomerTaskDTO = await Repository.getTasksOfACustomer(
    customerId
  );

  return tasksOfACustomer;
}

async function makePaymentForTasks(
  paymentDetails: CreateTaskPaymentDTO
): Promise<TaskPaymentDTO> {
  const taskPayment: TaskPaymentDTO = await Repository.makePaymentForTasks(
    paymentDetails
  );

  return taskPayment;
}

async function assignGroup(assignGroupDetails: TaskAssignDTO): Promise<void> {
  await Repository.assignGroup(assignGroupDetails);
}

async function reassignGroup(assignGroupDetails: TaskAssignDTO): Promise<void> {
  await Repository.reassignGroup(assignGroupDetails);
}

async function resetTask(taskId: string): Promise<TaskCustomerDTO> {
  const task: TaskCustomerDTO = await Repository.resetTask(taskId);

  return task;
}

async function updateTask(
  taskId: string,
  taskDetailsToUpdate: FormData
): Promise<TaskCustomerDTO> {
  const task: TaskCustomerDTO = await Repository.updateTask(
    taskId,
    taskDetailsToUpdate
  );

  return task;
}

export const TaskService = {
  getCycleStatus,
  getGroups,
  searchTask,
  getTasksOfAGroup,
  getEmployeeStatus,
  getTasksOfACustomer,
  makePaymentForTasks,
  assignGroup,
  reassignGroup,
  resetTask,
  updateTask
};
