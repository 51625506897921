const route = "/tasks";

const resetTask = "reset/";
const getGroups = `${route}/groups`;
const assignGroup = `${route}/assign`;
const statusOfCycle = `${route}/status`;
const reassignGroup = `${route}/reassign`;
const makePaymentOfTasks = `${route}/pay`;
const getTasksOfCustomer = `${route}/customer`;
const statusOfEmployee = `${route}/employee/status`;

export {
  route,
  resetTask,
  getGroups,
  assignGroup,
  statusOfCycle,
  reassignGroup,
  statusOfEmployee,
  getTasksOfCustomer,
  makePaymentOfTasks
};
