

import { Vue, Component } from "vue-property-decorator";

import { TaskService } from "@/services/task";
import { ReportService } from "@/services/report";

import {
  TaskCycleStatusDTO,
  TaskEmployeeDTO,
  TaskStatusDTO
} from "@/services/task/task.dto";

interface TableHeader {
  text: string;
  value: string;
}

interface TableHeader {
  text: string;
  value: string;
}

interface Employee extends TaskEmployeeDTO, TaskStatusDTO {
  pending: number;
}

@Component
export default class Dashboard extends Vue {
  loading = false;
  employees: Employee[] = [];
  status!: TaskCycleStatusDTO;
  headers: TableHeader[] = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Total",
      value: "total"
    },
    {
      text: "Completed",
      value: "completed"
    },
    {
      text: "Pending",
      value: "pending"
    },
    {
      text: "Door Locked",
      value: "doorLocked"
    },
    {
      text: "Unpaid",
      value: "unpaid"
    },
    {
      text: "Paid",
      value: "paid"
    }
    // {
    //   text: "",
    //   value: "progress"
    // }
  ];

  data() {
    return {
      status: {}
    };
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 1);
    this.$store.commit("liveData/setSelectedActivityName", "Dashboard");

    this.getStatus();
  }

  async getStatus() {
    this.loading = true;
    try {
      this.status = await TaskService.getCycleStatus();
      this.employees = this.status.employees.map(employee => ({
        ...employee,
        pending: employee.total - employee.completed
      }));
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  async generateReport() {
    try {
      await ReportService.generateReport({});
      alert(
        "Report will be generated shortly. You can view the report in the reports tab."
      );
    } catch (err) {
      console.log(err);
    }
  }

  async generateZips() {
    try {
      await ReportService.generateZips();
      alert(
        "Zips will be generated soon. You can view the status in the reports tab."
      );
    } catch (err) {
      console.log(err);
    }
  }

  goToEmployee(employeeId: string) {
    this.$router.push("/employee/" + employeeId);
  }
}
