import { Post, Get } from "@/services/Repository";
import { ERROR_CODES, BASE_URL } from "@/res/strings";

import { ReportDTO, ReportOptions } from "./report.dto";
import * as API from "./report.api";

async function parseExcel(cycleId: string, formData: FormData): Promise<void> {
  const response = await fetch(`${BASE_URL}/${API.parseExcel}`, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
      cycle: cycleId
      // "Content-Type": "multipart/form-data"
    }
  });

  if (!response.ok) {
    throw Error(ERROR_CODES.UNABLE_TO_CONNECT_TO_SERVER);
  }

  return;
}

async function getReports(): Promise<ReportDTO[]> {
  const response = await Get(API.route);

  return response as ReportDTO[];
}

async function generateZips(): Promise<void> {
  await Post(API.generateZips);
}

async function generateReport(options: ReportOptions): Promise<void> {
  await Post(API.generateReport, options);

  return;
}

export { parseExcel, getReports, generateReport, generateZips };
