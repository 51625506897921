import * as Repository from "./report.repository";

import { ReportDTO, ReportOptions } from "./report.dto";

import CycleStore from "@/services/cycle/cycle.store";
import { CycleDTO } from "@/services/cycle/cycle.dto";

async function parseExcel(formData: FormData): Promise<void> {
  const cycle: CycleDTO = CycleStore.state;
  await Repository.parseExcel(cycle.cycleId, formData);

  return;
}

async function getReports(): Promise<ReportDTO[]> {
  const reports: ReportDTO[] = await Repository.getReports();

  return reports;
}

async function generateZips(): Promise<void> {
  await Repository.generateZips();
}

async function generateReport(options: ReportOptions): Promise<void> {
  await Repository.generateReport(options);

  return;
}

export const ReportService = {
  parseExcel,
  getReports,
  generateReport,
  generateZips
};
