import { Get, Post, Patch } from "../Repository";

import { BASE_URL, ERROR_CODES } from "@/res/strings";

import * as API from "./task.api";
import {
  GroupDTO,
  GroupTaskDTO,
  TaskAssignDTO,
  TaskPaymentDTO,
  TaskCustomerDTO,
  CustomerTaskDTO,
  TaskCycleStatusDTO,
  CreateTaskPaymentDTO,
  TaskEmployeeStatusDTO,
  SearchTaskDTO
} from "./task.dto";

async function getCycleStatus(): Promise<TaskCycleStatusDTO> {
  const response = await Get(API.statusOfCycle);

  return response as TaskCycleStatusDTO;
}

async function searchTask(options: SearchTaskDTO): Promise<TaskCustomerDTO> {
  const response = await Get(API.route, options);

  return response as TaskCustomerDTO;
}

async function getGroups(): Promise<GroupDTO[]> {
  const response = await Get(API.getGroups);

  return response as GroupDTO[];
}

async function getTasksOfAGroup(groupDetails: {
  groupName: string;
  area: string;
  postalCode: string;
}): Promise<GroupTaskDTO> {
  const response = await Get(API.getGroups, groupDetails);

  return response as GroupTaskDTO;
}

async function getEmployeeStatus(
  employeeId: string
): Promise<TaskEmployeeStatusDTO> {
  const response = await Get(API.statusOfEmployee, { employeeId });

  return response as TaskEmployeeStatusDTO;
}

async function getTasksOfACustomer(
  customerId: string
): Promise<CustomerTaskDTO> {
  const response = await Get(API.getTasksOfCustomer, { customerId });

  return response as CustomerTaskDTO;
}

async function makePaymentForTasks(
  paymentDetails: CreateTaskPaymentDTO
): Promise<TaskPaymentDTO> {
  const response = await Post(API.makePaymentOfTasks, paymentDetails);

  return response as TaskPaymentDTO;
}

async function assignGroup(assignGroupDetails: TaskAssignDTO): Promise<void> {
  await Patch(API.assignGroup, assignGroupDetails);
}

async function reassignGroup(assignGroupDetails: TaskAssignDTO): Promise<void> {
  await Patch(API.reassignGroup, assignGroupDetails);
}

async function resetTask(taskId: string): Promise<TaskCustomerDTO> {
  const response = await Patch(`${API.route}/${taskId}/${API.resetTask}`);

  return response as TaskCustomerDTO;
}

async function updateTask(
  taskId: string,
  taskDetailsToUpdate: FormData
): Promise<TaskCustomerDTO> {
  const response = await fetch(`${BASE_URL}/${API.route}/${taskId}`, {
    method: "PATCH",
    body: taskDetailsToUpdate,
    headers: {
      Accept: "application/json"
      // "Content-Type": "multipart/form-data"
    }
  });

  if (!response.ok) {
    throw Error(ERROR_CODES.UNABLE_TO_CONNECT_TO_SERVER);
  }

  const uploadedTask: TaskCustomerDTO = await response.json();
  return uploadedTask;
}

export {
  getGroups,
  resetTask,
  searchTask,
  updateTask,
  assignGroup,
  reassignGroup,
  getCycleStatus,
  getTasksOfAGroup,
  getEmployeeStatus,
  getTasksOfACustomer,
  makePaymentForTasks
};
